import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import ContactUs from 'pages/contact_us/SamplePage';
// import HelpAndSupport from 'pages/helpAndSupport/SamplePage';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
// render - sample page
const Products = Loadable(lazy(() => import('pages/products/SamplePage')));
const ProductDetail = Loadable(lazy(() => import('pages/productDetails/SamplePage')));
const ProductsAdd = Loadable(lazy(() => import('pages/productsAdd/SamplePage')));
const ProductsAddNew = Loadable(lazy(() => import('pages/productsAddNew/SamplePage')));

const HelpAndSupportReq=Loadable(lazy(() => import('pages/help_and_support_req/SamplePage')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Products />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'products',
      element: <Products />
    },
    {
      path: 'productsAdd',
      element: <ProductsAddNew />
    },
    {
      path: 'products-add-new',
      element: <ProductsAddNew />
    },
    {
      path: 'contact_us',
      element: <ContactUs />
    },
    {
      path: 'helpAndSupportReq',
      element: <HelpAndSupportReq />
    },
    {
      path: 'product_detail/:id',
      element: <ProductDetail />
    },
  
  ]
};

export default MainRoutes;
