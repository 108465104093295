// assets
import { DashboardOutlined,FileOutlined,MessageOutlined ,PhoneOutlined,InboxOutlined,AppstoreOutlined,AppstoreAddOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  InboxOutlined,
  AppstoreAddOutlined ,
  AppstoreOutlined ,
  MessageOutlined ,
  PhoneOutlined ,
  FileOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  // title: 'Navigation',
  type: 'group',
  children: [
    // {
    //   id: 'dashboard',
    //   title: 'Dashboard',
    //   type: 'item',
    //   url: '/dashboard/default',
    //   icon: icons.DashboardOutlined,
    //   breadcrumbs: false
    // },
    {
      id: 'products',
      title: 'Products',
      type: 'item',
      url: '/products',
      icon: icons.InboxOutlined,
      breadcrumbs: false
    },
    {
      id: 'contact_us',
      title: 'Contact us',
      type: 'item',
      url: '/contact_us',
      icon: icons.PhoneOutlined,
      breadcrumbs: false
    },
    {
      id: 'helpAndSupportReq',
      title: 'Help And Support Requests',
      type: 'item',
      url: '/helpAndSupportReq',
      icon: icons.FileOutlined,
      breadcrumbs: false
    },
   
  
  ]
};

export default dashboard;
