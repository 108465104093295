// material-ui
import {
  Typography, Grid,
} from '@mui/material';
import OrdersTable from './OrdersTable';
import AnalyticEcommerce from 'components/cards/statistics/AnalyticEcommerce';
import { useEffect, useState } from 'react';
import { post } from 'Urls/api';
// ==============================|| SAMPLE PAGE ||============================== //
const ContactUs = () => {
  const [monthly_subscriptions, setMonthly_Subscriptions] = useState(0)
  const [yearly_subscriptions, setYearly_Subscriptions] = useState(0)
  const [total_open_tickets, setTotal_Open_tickets] = useState(0)

  const fetchAllproducts = async () => {
    try {

      const apiData = await post('user/dashboardAdminCount'); // Specify the endpoint you want to call
      console.log("ALL Dashboard Count")

      console.log(apiData)

      if (apiData.error) {


        // toastAlert("error", apiData.message)


        // setNextloader(false)
      } else {
   
        setTotal_Open_tickets(apiData.open_tickets_contact)
        setMonthly_Subscriptions(apiData.resolved_tickets_contact)
        setYearly_Subscriptions(apiData.rejected_tickets_contact)

      }
    } catch (error) {
      console.error('Error fetching data:', error);

    }
  }
  useEffect(() => {
    fetchAllproducts()
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h3" style={{ fontWeight: 700 }}>Contact Us</Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AnalyticEcommerce title="Total Open Tickets" count={total_open_tickets} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AnalyticEcommerce title="Total Resolved Tickets" count={yearly_subscriptions} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <AnalyticEcommerce title="Total Rejected Tickets" count={monthly_subscriptions} />
            </Grid>
          </Grid>
          </Grid>
        <Grid item xs={12} md={12}>
          <OrdersTable />
        </Grid>

      </Grid>

    </>
  )
};

export default ContactUs;
